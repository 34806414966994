.view-inventory-item-attributes {
  &_list {
    &_item {
      padding: calc(#{$outside-card-padding} / 2) $outside-card-padding;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      p {
        margin-bottom: 0;
        text-align: center;
        font-size: 1.05em;
      }

      @media screen and (min-width: 420px) {
        flex-direction: row;
        justify-content: space-between;

        p {
          text-align: initial;
        }
      }

      &:nth-child(even) {
        background-color: $offWhite2;
      }
    }
  }

  &_empty-message {
    text-align: center;
    margin-bottom: 0;
    font-style: italic;
    padding: $outside-card-padding;
  }
}
