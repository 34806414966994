.partner-inventory-item-name-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  &_thumbnail {
    width: 52px;
    height: 52px;
    border-radius: 8px;
    overflow: hidden;
    border: $outside-card-border;

    &_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_name {
    font-weight: bold;
  }
}
