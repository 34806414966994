.user-homepage {
  background-color: #F9F9F9;

  &__container {
    padding-top: 8rem;
    color: $white;
  }

  &__background {
    width: 100%;
    height: 375px;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__recommended {
    padding-top: 4rem;
  }
}
