.reset-successful-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &_container {
    h1 {
      margin: 1.5rem 0;
    }

    p {
      margin: 0;
    }

    &_login-button {
      margin: 2rem 0;

      .btn {
        border-radius: 5px;

        a {
          text-decoration: none;
          color: white;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .reset-successful-page {
    &_container {
      margin-top: 50px;
      margin-bottom: 1rem;
      padding: 0 .5rem;

      &_form_button {
        width: 100%;

        .btn {
          width: 100%;
          max-width: 359px;
        }
      }
    }
  }
}