.profile-image-picker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  &-input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  &_preview-container {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 128px;
    height: 128px;
    border-radius: 50%;
    background-color: $forest-green;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &_placeholder {
      @include svg-sizer(30%);
      color: $offWhite;
    }
  }

  &_button-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(sm) {
      justify-content: flex-start;
    }
  }
  &-button {
    margin: 0.5rem;
  }
}
