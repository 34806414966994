.sidebar {
  width: 290px;
  height: 100vh;
  overflow-y: auto;
  background-color: white;

  &_close-header-container {
    @include flex(row, flex-start);
    padding-bottom: 1rem;
    margin: 1rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid $silverGray;

    &_x {
      @include svg-sizer(1.5rem);
      transition: all 0.2s;
      color: #292323;

      &:hover {
        cursor: pointer;
        color: rgba(grey, 0.8);
      }
    }

    &_title {
      padding-right: 24px;
      margin: auto;
    }
  }

  &_inner {
    text-align: left;
    width: 100%;
    height: 100%;
    padding: 0;

    &_content {
      &_title-container {
        padding: 1.5rem 0 1rem 1.25rem;

        &_title {
          font-size: 14pt;
          text-align: left;
          color: #6E6B6B;
          margin-bottom: 0;
        }
      }

      &_button-list-container {
        @include flex(column);
        width: 100%;
        padding: 0;

        &_link {
          text-decoration: none !important;
        }

        &_button {
          margin-bottom: 0.35rem;
        }
      }

      &_close-button {
        padding: 1rem 0;
      }
    }
  }
}

@keyframes pulsate-sidebar-icon {
  0% {
    transform: scale(1) rotate(0deg);
  }
  25% {
    transform: scale(0.85) rotate(-12deg);
  }
  50% {
    transform: scale(1) rotate(0deg);
  }
  75% {
    transform: scale(0.85) rotate(12deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

.custom-sidebar-nav-classes {
  &_demo-page-animator {
    svg {
      animation: pulsate-sidebar-icon 4.5s;
      animation-iteration-count: infinite;
      animation-timing-function: ease;
    }
  }
}
