$sub-header-color: #6E6B6B;

.company-legal-agreements-page {
  padding-top: $non-authenticated-page-padding-top;
  background-color: #F9F9F9;

  @include media-breakpoint-up(sm) {
    padding-top: $authenticated-page-padding-top;
  }

  &_header {
    &_title {
      margin-top: 1rem;
    }

    &_terms-contract-date {
      color: $sub-header-color;
    }
  }

  &_body {
    &_section {
      border-top: 1px solid $silverGray;

      &_header {
        margin-top: 2rem;
      }

      &_sub-header {
        margin-bottom: 2rem;
        color: $sub-header-color;
      }
    }
  }
}