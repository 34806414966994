.event-update-card {
  @include flex(row, flex-start, center);
  font-size: $reg-font-size;
  padding: .5rem 0;
  border-bottom: $border-bottom;

  &_date {

  }

  &_img {
    margin: 0 1rem;

    img {
      border-radius: 8px;
    }
  }

  &_description {

    &_title {
      text-decoration: underline;
      color: #292323;

      &:hover {
        color: black;
      }
    }

    &_subtitle {
      font-size: $reg-font-size;
      margin-bottom: 0;
    }
  }
}