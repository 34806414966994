@mixin mini-tag-template($name, $color, $background-color) {
  .mini-tag-#{$name} {
    @extend .biryani_bold;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 1rem 1rem 0;
    padding: 0.3rem 1.1rem;
    border: 1px solid $color;
    border-radius: 15px;
    background-color: $background-color;
    color: $color;

    &:hover {
      opacity: .6;
    }
  }
}

@include mini-tag-template(blueLight, $blue-dark, $blue-light);
@include mini-tag-template(forestGreen, $forest-green, $forest-green-0);
@include mini-tag-template(grayscale, $grayscale-700, $grayscale-100);