.outside-basic-modal-message {
  display: flex;
  flex-direction: column;
  align-items: center;

  &_icon-container {
    padding: 1.75rem;
    padding-top: 1rem;

    svg {
      fill: $safety-orange;
      @include svg-sizer(80px);
    }
  }

  &_title {
    font-size: 22px;
  }

  &_description {
    @extend .biryani_regular;
    font-size: 16px;
    text-align: center;
    color: $gray-600;
  }
}
