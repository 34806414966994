.event-update-list {
  border: 1px solid $silverGray;

  &_header {
    border-bottom: $border-bottom;

    h2 {
      font-size: 22px;
      margin: 1rem;
    }
  }

  &_list {
    list-style-type: none;
    padding: 0 1rem 0 1rem;


    &_date-header {
      padding: 1rem 0 .25rem 0;
      border-bottom: $border-bottom;
    }
  }

  &_footer-link {
    @include flex(row, center, center);
    text-decoration: underline;
    margin-bottom: 1rem;
    color: #292323;

    &:hover {
      color: black;
    }
  }
}