.company-photo {
  width: 100%;
  aspect-ratio: 1;
  background-color: $darkBlue;
  border-radius: 5px;
  overflow: hidden;
  @include flex(row, center, center);
  transition: all 0.2s;
  position: relative;

  &_clicker {
    cursor: pointer;

    &:hover {
      opacity: 0.85;
    }
  }

  &_no-bg {
    background-color: transparent;
    align-items: normal;
  }

  &_image {
    width: 100%;
    object-fit: cover;
  }

  &_placeholder {
    @include svg-sizer(30%);
    color: $offWhite;
  }
}
