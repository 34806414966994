@mixin custom-input {
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .inputfile + label {
    @extend .biryani_bold;
    cursor: pointer;
    font-size: 16px;
    display: inline-block;
    padding: 8px 32px;
    border: 1px solid $silverGray;
    border-radius: 8px;
    background-color: white;
    color: #292323;
  }

  .inputfile:focus + label,
  .inputfile + label:hover {
    border-color: $forest-green;
  }
}
