.outside-paginator {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    justify-content: flex-end;
  }

  &_limiter-container {
    &_inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }

      &_label {
        color: $grayscale-600;
        white-space: nowrap;
        margin-bottom: 0;
        font-size: 0.9em;
      }

      &_input-container {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;

        &_label {
          margin-bottom: 0;
          font-size: 0.9em;
        }

        &_select {
          width: 70px;
          height: 34px;
          border-radius: 8px;
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 0.5rem;
          padding-right: 0;
        }
      }
    }
  }

  &_buttons-container {
    display: flex;
    flex-direction: row;

    .outside-paginator-button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.9em;
      min-width: 32px;
      padding: 0.25rem 0.6rem;
      cursor: default !important;
      transition: all 0.2s;
      background-color: transparent;
      border: none;
      border-radius: 8px;

      &_clickable {
        color: $black;

        &:hover {
          cursor: pointer !important;
          text-decoration: underline;
        }
      }

      &_active {
        color: $grayscale-700;
        background-color: $grayscale-100;
      }
    }
  }
}
