.icon-button-container {
  display: inline-flex;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;

  &-safetyOrange_button {
    background-color: $safety-orange;
    border-radius: 50%;
    cursor: pointer;
    @include flex(row, center, center);
    transition: all 0.2s;
    border: 1px solid $safety-orange;

    &:hover {
      background-color: $safety-orange-800;
      cursor: pointer;
      border-color: $safety-orange-800;

      .icon-button-container-safetyOrange_button_icon {
        color: white;
      }
    }

    &:active {
      background-color: $safety-orange-800;
    }

    &_icon {
      @include svg-sizer(55%);
      transition: all 0.2s;
      color: white;
    }
  }

  &-redDark_button {
    @include flex(row, center, center);
    cursor: pointer;
    background-color: transparent;
    border-radius: 50%;
    transition: all 0.2s;
    border: 1px solid transparent;

    &:hover {
      cursor: pointer;
    }

    &_icon {
      @include svg-sizer(55%);
      transition: all 0.2s;
      color: $red-dark;
    }
  }

  &-orange_button {
    @include flex(row, center, center);

    transition: all 0.2s;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid transparent;

    &:hover {
      cursor: pointer;

      .icon-button-container-orange_button_icon {
        color: $safety-orange;
      }
    }

    &:active {
      color: $safety-orange;
    }

    &_icon {
      @include svg-sizer(55%);
      color: $grayscale-800;
    }
  }

  &-forest-green_button {
    background-color: $forest-green;
    border-radius: 50%;
    cursor: pointer;
    @include flex(row, center, center);
    transition: all 0.2s;
    border: 1px solid $forest-green;

    &:hover {
      background-color: $forest-green-800;
      cursor: pointer;
      border-color: $forest-green-800;

      .icon-button-container-forest-green_button_icon {
        color: white;
      }
    }

    &:active {
      background-color: $forest-green-800;
    }

    &_icon {
      @include svg-sizer(55%);
      transition: all 0.2s;
      color: white;
    }
  }

  &-grayscale_button {
    @include flex(row, center, center);
    cursor: pointer;
    transition: all 0.2s;
    border: 1px solid $grayscale-400;
    border-radius: 50%;
    background-color: transparent;

    &:hover {
      cursor: pointer;
      border-color: $red-dark;
      background-color: transparent;

      .icon-button-container-grayscale_button_icon {
        color: $red-dark !important;
      }
    }

    &:active {
      background-color: transparent;
    }

    &_icon {
      @include svg-sizer(55%);
      transition: all 0.2s;
      color: $grayscale-800;
    }
  }
}
