.user-first-time-form-page {
  &_header {
    &_sub-title {
      color: #6E6B6B;
    }
  }

  &_country-code-input {
    min-width: 100px;
    width: 100px;
  }
}
