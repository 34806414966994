$full-tag-horizontal-padding: 0.5rem;

.full-tag {
  @include shadow-low;
  @extend .biryani_bold;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  border: 1px solid $grayscale-600;
  border-radius: 15px;
  padding: 0.4rem $full-tag-horizontal-padding;

  &_hover {
    cursor: pointer;
  }

  &__activity-icon {
    padding-right: $full-tag-horizontal-padding;

    svg {
      @include svg-sizer(20px);
    }
  }

  &__label {
    color: $grayscale-800;
  }

  &__close-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: $full-tag-horizontal-padding;

    svg {
      @include svg-sizer(20px);
    }
  }

  &:hover {
    .full-tag__close-icon {
      transform: scale(1.1);
      transition: all 0.2s ease-in;
    }
  }
}
