// header and paragraph elements

h1 {
  @extend .biryani_extra_bold;
  font-size: 32px;
}

h2 {
  @extend .biryani_extra_bold;
  font-size: 22px;
}

h3 {
  @extend .biryani_extra_bold;
  font-size: 18px;
}

h4 {
  @extend .biryani_bold;
  font-size: 16px;
}

p {
  @extend .biryani_regular;
  font-size: 16px;
}

.label-p2 {
  @extend .biryani_semi_bold;
  font-size: 16px;
}

.label-p3 {
  @extend .biryani_semi_bold;
  font-size: 14px;
}