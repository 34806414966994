.view-booking-bundle-details {
  &_thumbnail-container {
    width: 100%;
    max-width: 220px;
    aspect-ratio: 11 / 8;
    background-color: $grayscale-200;
    border: 1px solid #E0E0E0;
    border-radius: 5px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }

  &_bundle-name {
    margin-top: 1.5rem;
    line-height: 26px;
  }

  &_items-quantity {
    margin-bottom: 0;
    color: $mediumGray;
  }
}
