// classes for LargeCard and SmallCard components

.large-card {
  display: flex;
  flex-direction: column;
  border: 1px solid $grayscale-600;
  border-radius: 15px;
  width: 430px;
  height: 280px;

  &__image {
    height: 50%;

    img {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    height: 50%;
    padding: 1rem;
    border-top: 1px solid $grayscale-600;


    &__title {
      @extend .biryani_bold;
      font-size: 28px;
      margin-bottom: .2rem;
    }

    &__rating {
      @extend .biryani_regular;
      font-size: 18px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: .2rem;

      &-stars {
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
          fill: red;
        }
      }

      &-number {
        padding-left: .75em;
        color: $grayscale-800;
      }
    }

    &__text {
      @extend .biryani_regular;
      font-size: 18px;
      margin-bottom: .2rem;
      color: $grayscale-800;
    }
  }
}

.small-card {
  display: flex;
  flex-direction: column;
  border: 1px solid $grayscale-600;
  border-radius: 15px;
  width: 220px;
  height: 170px;

  &__image {
    height: 50%;

    img {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    height: 50%;
    padding: 1rem;
    border-top: 1px solid $grayscale-600;

    &__title {
      @extend .biryani_bold;
      font-size: 22px;
      margin-bottom: .2rem;
    }

    &__text {
      @extend .biryani_regular;
      font-size: 18px;
      margin: 0;
      color: $grayscale-800;
    }
  }
}

@include media-breakpoint-down(lg) {
  .large-card__footer {
    &__title {
      font-size: 24px;
    }

    &__rating {
      font-size: 19px;
    }

    &__text {
      font-size: 19px;
    }
  }
}

@include media-breakpoint-down(md) {
  .large-card {
    width: 300px;
    height: 220px;

    &__footer {
      &__title {
        font-size: 19px;
      }

      &__rating {
        font-size: 13px;
      }

      &__text {
        font-size: 13px;
      }
    }
  }

  .small-card__footer {
    &__title {
      font-size: 18px;
    }

    &__text {
      font-size: 15px;
    }
  }
}

