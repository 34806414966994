.side-bar-button {
  overflow: hidden;
  border: none;
  background-color: transparent;

  @include flex(row, flex-start, center);
  width: 100%;
  padding: 0.4rem 1.25rem;
  color: #292323;
  transition: all 0.2s;
  cursor: pointer;
  user-select: none;

  svg {
    @include svg-sizer(1.4em);
    margin-right: 0.6em;
    color: #A19F9F;
  }

  &_user-selected {
    color: #292323;
    background-color: transparent;
    background-image: linear-gradient(90deg, #F9F9F9 0 98%, $forest-green 98% 100%);

    span, svg {
      color: $forest-green;
    }
  }

  &_admin-selected {
    color: #292323;
    background-color: transparent;
    background-image: linear-gradient(90deg, #F9F9F9 0 98%, $safety-orange 98% 100%);

    span, svg {
      color: $safety-orange;
    }
  }

  &:focus {
    outline: none !important;
  }

  span {
    margin-top: 0.075rem;
  }
}
