.add-inventory-schedule {
  &_availabilities {
    margin-bottom: $outside-card-padding;

    &_tags {
      width: 100%;
      display: inline-flex;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      justify-content: center;
      gap: $outside-card-padding;
      flex-wrap: wrap;
    }

    &_empty-message-container {
      p {
        margin: 0;
        text-align: center;
        font-style: italic;
      }
    }
  }

  &_button-container {
    display: flex;
    justify-content: center;
  }
}

.add-inventory-schedule-modal {
  display: flex;
  justify-content: center;
}
