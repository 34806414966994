$background-color: white;

.company-page {
  margin: 0 1rem;
  padding-top: $non-authenticated-page-padding-top;

  @include media-breakpoint-up(sm) {
    margin: 0 4rem;
  }

  @include media-breakpoint-up(md) {
    padding-top: $authenticated-page-padding-top;
  }

  h1 {
    margin-top: 1.5rem;
  }

  &_container {
    margin-bottom: 0.5rem;
    padding: 0;
    border: 1px solid $silverGray;
    background-color: $background-color;

    &_name {
      border-bottom: $border-bottom;
      padding: 1rem;
    }

    &_inner {
      padding: 1rem;

      &_company-logo {
        border-bottom: $border-bottom;
        margin-bottom: 1rem;

        &_image {
          overflow: hidden;
          width: 128px;
          height: 128px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1rem;
          border-radius: 50%;

          img {
            width: 128px;
            height: 128px;
          }
        }

        &_bio {
          width: 75%;

          h4 {
            margin-top: 1rem;
          }
        }
      }
    }

    &_actions {
      display: flex;
      justify-content: center;
      padding: 0 1rem 1rem 1rem;

      @include media-breakpoint-up(sm) {
        justify-content: flex-start;
      }

      &_edit {
        margin-right: .5rem;
      }
    }
  }
}