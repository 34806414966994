.add-inventory-picture {
  &_preview-row {
    width: 100%;
    display: inline-flex;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    flex-direction: row;
    gap: $outside-card-padding;
    margin-bottom: $outside-card-padding;

    &_col-left {
      @include flex-row-item-defined-width-helper($outside-card-padding, 2, 40%);

      &_image-container {
        width: 100%;
        aspect-ratio: 1;
        border-radius: 8px;
        overflow: hidden;
        border: $outside-card-border;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          user-select: none;
        }
      }
    }

    &_col-right {
      @include flex-row-item-defined-width-helper($outside-card-padding, 2, 60%);
    }
  }

  &_editing-buttons {
    width: 100%;
    display: flex;
    justify-content: center;

    &_inner {
      width: 100%;
      display: inline-flex;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      flex-direction: row;
      flex-wrap: wrap;
      gap: $outside-card-padding;
      max-width: 400px;

      @include media-breakpoint-up(md) {
        max-width: initial;
      }

      &_col {
        width: 100%;

        @media screen and (min-width: 460px) {
          @include flex-row-item-width-helper($outside-card-padding, 2);
        }

        @media screen and (min-width: map-get($grid-breakpoints, md)) {
          width: 100%;
        }

        @media screen and (min-width: map-get($grid-breakpoints, xl)) {
          @include flex-row-item-width-helper($outside-card-padding, 2);
        }

        button {
          width: 100%;
          max-width: initial;
        }
      }
    }
  }

  &_empty {
    &_button-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}

