.outside-icon-button {
  display: inline-flex;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  background-color: $safety-orange;
  padding: 0.6rem;
  transition: all 0.2s;
  border-radius: 8px;

  &_rounded {
    border-radius: 50%;
  }

  &_inverted {
    background-color: transparent;
    border: 1px solid $grayscale-700;
    color: $grayscale-700;

    &:hover {
      filter: none !important;
    }

    svg {
      color: inherit !important;
    }
  }

  &:hover {
    cursor: pointer;
    filter: brightness(0.9);
  }

  svg {
    @include svg-sizer(1em);
    color: $white;
  }
}
