$removable-image-close-icon-size: 34px;

.removable-image {
  width: 100%;
  aspect-ratio: 1;
  position: relative;
  border: $outside-card-border;
  border-radius: 8px;
  user-select: none;

  &:hover {
    .removable-image_close-container {
      display: flex;
    }
  }

  img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &_close-container {
    display: none;
    position: absolute;
    top: calc(#{$removable-image-close-icon-size} / 2 * -1);
    right: calc(#{$removable-image-close-icon-size} / 2 * -1);
    height: $removable-image-close-icon-size;
    width: $removable-image-close-icon-size;
    border-radius: 50%;
    transition: all 0.2s;
    justify-content: center;
    align-items: center;
    background-color: $safety-orange;

    &:hover {
      cursor: pointer;
      background-color: $safety-orange-800;
    }

    svg {
      @include svg-sizer(70%);
      color: $white;
    }
  }
}
