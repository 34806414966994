$outside-card-border: 1px solid $border-gray;
$outside-card-padding: 1.2rem;

.outside-card {
  background-color: $white;
  border: $outside-card-border;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.outside-card-section {
  padding: $outside-card-padding;
  border-bottom: $outside-card-border;

  &:last-of-type {
    border-bottom: none;
  }
}

.outside-card-section-flush {
  @extend .outside-card-section;
  padding: 0;
}

.outside-card-header {
  @extend h2;
  margin-bottom: -0.4rem;
}
