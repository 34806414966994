.profile-image-mini {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem;

  &_image-container {
    padding: 3px;
    border: 1px solid $grayscale-600;
    border-radius: 50%;

    &_inner-container {
      overflow: hidden;
      width: 55px;
      border-radius: 50%;


      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &_text-container {
    display: flex;
    flex-direction: column;
    margin-left: 1em;

    &_name {
      @extend .biryani_extra_bold;
      font-size: 16px;
      padding-bottom: 0;
      margin: 0;
    }

    &_location {
      @extend .biryani_regular;
      font-size: 16px;
      margin: 0;
      color: $grayscale-600;
    }
  }
}