$page-tab-switcher-height: 44px;

.page-tab-switcher {
  height: $page-tab-switcher-height;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  border-bottom: 1px solid $grayscale-300;

  &_item {
    height: 100%;
    display: inline-flex;
    align-items: center;
    border-top: solid 4px transparent;
    border-bottom: solid 4px transparent;
    transition: all 0.2s;
    padding-left: 7px;
    padding-right: 7px;
    user-select: none;
    cursor: pointer;
    color: $black;

    &_disabled {
      pointer-events: none;
    }

    &_selected {
      pointer-events: none;
    }

    &_user-selected {
      border-bottom-color: $forest-green;
    }

    &_admin-selected {
      border-bottom-color: $safety-orange;
    }

    &_selectable {
      &:hover {
        border-bottom-color: $grayscale-400;
      }
    }

    &:hover {
      color: $black;
    }
  }
}
