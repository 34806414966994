.preview-avail-checker {
  width: 100%;
  border: 2px solid $grayscale-300;
  background-color: $white;
  padding: 1.5rem;

  &_date-row {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    @media screen and (min-width: 420px) {
      flex-direction: row;
    }

    @include media-breakpoint-up(md) {
      flex-direction: column;
    }

    @include media-breakpoint-up(xl) {
      flex-direction: row;
    }

    &_col {
      width: 100%;

      @media screen and (min-width: 420px) {
        width: 50%;
      }

      @include media-breakpoint-up(md) {
        width: 100%;
      }

      @include media-breakpoint-up(xl) {
        width: 50%;
      }
    }

    &_col-icon {
      display: flex;
      justify-content: center;
      order: 1;

      @media screen and (min-width: 420px) {
        order: 2;
      }

      @include media-breakpoint-up(md) {
        order: 1;
      }

      @include media-breakpoint-up(xl) {
        order: 2;
      }
    }

    &_col-input {
      .outside-date-picker {
        width: 100%;
      }

      order: 2;

      @media screen and (min-width: 420px) {
        order: 1;
      }

      @include media-breakpoint-up(md) {
        order: 2;
      }

      @include media-breakpoint-up(xl) {
        order: 1;
      }
    }
  }

  &_no-message {
    font-size: 0.9em;
    color: $red;
    margin-bottom: 0;
    margin-top: 1.5rem;
  }

  &_yes-message {
    font-size: 0.9em;
    color: $green;
    margin-bottom: 0;
    margin-top: 1.5rem;
  }

  .outside-button-2 {
    margin-top: 1.5rem;
    width: 100%;
  }
}
