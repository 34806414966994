.summary-grid {
  width: 100%;
  padding: 1rem;
  border: 1px solid $silverGray;
  background-color: #FFFFFF;

  &_container {
    &_header {
      @include flex(row, space-between, center);
      border-bottom: $border-bottom;
      padding-bottom: 1rem;

      h2.biryani_extra_bold {
        margin-bottom: 0;
      }

      &_buttons {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-up(sm){
          display: flex;
          flex-direction: row;
        }

        .btn-link {
          text-decoration: underline;
          color: #292323;
        }

        .btn-safetyOrange {
          @include flex(row, center, center);
          white-space: nowrap;
          padding: .25rem 1rem;
          border-radius: 8px;
        }
      }
    }

    &_horizontal-rule {
      border-bottom: $border-bottom;
    }

    &_grid-item {
      @include media-breakpoint-up(sm){
        border-bottom: $border-bottom;

        &:last-of-type {
          border-bottom: none;
        }
      }


      @include media-breakpoint-only(md) {
        border-bottom: $border-bottom;

        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
}
