$confirm-booking-date-selection-gap: 1rem;

.confirm-booking-date-selection {
  &_info {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: $confirm-booking-date-selection-gap;

    &_col {
      width: 100%;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 420px) {
        @include flex-row-item-defined-width-helper($confirm-booking-date-selection-gap, 2, 50%);
      }

      @include media-breakpoint-up(xl) {
        @include flex-row-item-defined-width-helper($confirm-booking-date-selection-gap, 3, 33%);
      }
    }
  }
}
