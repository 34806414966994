$safety-orange: #E85D20;
$safety-orange-0: #FFF8F4;
$safety-orange-100: #FFEADB;
$safety-orange-200: #FFDAC1;
$safety-orange-300: #FFC8A3;
$safety-orange-400: #FEB380;
$safety-orange-500: #FE9A56;
$safety-orange-600: #FE7926;
//700 is just safety-orange
$safety-orange-800: #B84A19;
$safety-orange-900: #6D2C0F;

$forest-green: #079F3F;
$forest-green-0: #E8FFF1;
$forest-green-100: #AEFFCE;
$forest-green-200: #49FF92;
$forest-green-300: #10F361;
$forest-green-400: #0FE25A;
$forest-green-500: #0DCE53;
$forest-green-600: #0AB94A;
//700 is just forest-green
$forest-green-800: #047D32;
$forest-green-900: #014A1E;

$grayscale-0: #FAF9F9;
$grayscale-100: #F0EDEB;
$grayscale-200: #E4E0DD;
$grayscale-300: #D8D2CE;
$grayscale-400: #CBC2BD;
$grayscale-500: #BCB1AA;
$grayscale-600: #AB9D94;
$grayscale-700: #97867B;
$grayscale-800: #79695F;
$grayscale-900: #473D37;

$blue-light: #EAECFE;
$blue-dark: #4356F9;

$red-light: #FFE8EA;
$red-dark: #DA0018;

$offWhite: #FAFAFA;
$offWhite2: #F9F9F9;

$lightGray: #EEEEEE;
$mediumGray: #9E9E9E;
$darkGray: #616161;
$silverGray: #C4C3C3;

$border-gray: #C4C3C3;

$darkBlue: #0F49AC;

$darkPurple: #0C0924;

:export {
  safetyOrange: $safety-orange;
  safetyOrange0: $safety-orange-0;
  safetyOrange100: $safety-orange-100;
  safetyOrange200: $safety-orange-200;
  safetyOrange300: $safety-orange-300;
  safetyOrange400: $safety-orange-400;
  safetyOrange500: $safety-orange-500;
  safetyOrange600: $safety-orange-600;
  safetyOrange800: $safety-orange-800;
  safetyOrange900: $safety-orange-900;

  forestGreen: $forest-green;
  forestGreen0: $forest-green-0;
  forestGreen100: $forest-green-100;
  forestGreen200: $forest-green-200;
  forestGreen300: $forest-green-300;
  forestGreen400: $forest-green-400;
  forestGreen500: $forest-green-500;
  forestGreen600: $forest-green-600;
  forestGreen800: $forest-green-800;
  forestGreen900: $forest-green-900;

  grayscale0: $grayscale-0;
  grayscale100: $grayscale-100;
  grayscale200: $grayscale-200;
  grayscale300: $grayscale-300;
  grayscale400: $grayscale-400;
  grayscale500: $grayscale-500;
  grayscale600: $grayscale-600;
  grayscale700: $grayscale-700;
  grayscale800: $grayscale-800;
  grayscale900: $grayscale-900;

  blueLight: $blue-light;
  blueDark: $blue-dark;

  redLight: $red-light;
  redDark: $red-dark;

  offWhite: $offWhite;
  offWhite2: $offWhite2;
  lightGray: $lightGray;
  mediumGray: $mediumGray;
  darkGray: $darkGray;
  silverGray: $silverGray;

  borderGray: $border-gray;

  darkBlue: $darkBlue;

  darkPurple: $darkPurple;
}
