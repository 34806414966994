.navbar-container {
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  box-shadow: 0 3px 15px #00000029;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 85px;
    padding: 0 3rem;

    @include media-breakpoint-up(lg) {
      padding: 0 5rem;
    }


    &_company {
      display: flex;
      align-items: center;

      &_hamburger-icon {
        display: none;
      }

      &_logo {
        padding-right: 1rem;
      }

      &_title {
        vertical-align: middle;
        margin-bottom: 0;
        font-size: 1.5rem;
        color: white;
      }
    }

    &_user-links {
      display: flex;
      justify-content: space-between;

      a {
        padding: .25rem .5rem;
        margin: 0 .25rem;
        border: 1px solid transparent;
        border-radius: 10px;
        color: white;

        &:hover,
        &:focus {
          outline: none;
          text-decoration: none;
          border: 1px solid white;

          &:last-of-type {
            border-radius: 50%;
          }
        }
      }

      .selected-user-link {
        background-color: white;
        color: #068842;
      }
    }
  }

  &-footer {
    width: 100%;
    height: 85px;
    padding-left: 167px;
    border: 1px solid $silverGray;
    background-color: white;

    @include media-breakpoint-up(lg) {
      padding-left: 199px;
    }

    &_page-links {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;

      a {
        display: flex;
        align-items: center;
        height: 100%;
        margin-right: 1rem;
        border-bottom: 5px solid transparent;
        color: black;

        &:hover,
        &:focus {
          outline: none;
          text-decoration: none;
          border-color: $silverGray;
        }
      }

      .selected-page-link {
        border-color: #068842;
      }
    }
  }
}

.non-standard-user-theme {
  .navbar-container {
    &-header {
      background-color: #E85D20;
      border: 1px solid #A72A14;
    }

    &-footer {
      &_page-links {
        .selected-page-link {
          border-color: #E85D20 !important;
        }
      }
    }
  }
}

.standard-user-theme {
  .navbar-container-header {
    background-color: #068842;
    border: 1px solid $forest-green-900;
  }
}

@include media-breakpoint-down(md) {
  .navbar-container-header_user-links {
    width: 30%;
  }

  .navbar-container-footer {
    padding-left: 199px;;
  }
}

@include media-breakpoint-down(sm) {
  .navbar-container {
    height: 92px;

    &-header {
      height: 100%;
      width: 100%;
      padding: 0 1.5rem;

      &_company {
        display: flex;
        width: 100%;

        &_hamburger-icon {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          color: white;

          svg {
            @include svg-sizer(1.5rem);
          }
        }

        &_logo {
          margin: auto;
          padding: 0;
        }

        &_title {
          display: none;
        }
      }

      &_user-links {
        display: none;
      }
    }

    &-footer {
      display: none;
      height: 0;
    }
  }
}
