.inventory-feedback-block {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  border: 1px solid $silverGray;
  border-radius: 8px;
  background-color: #EEEDED;

  &_icon {
    margin-right: 1rem;

    svg {
      @include svg-sizer(22px);
      color: #FE3328;
    }
  }

  &_container {
    width: 100%;

    &_header {
      @include flex(row, space-between, center);
      margin-top: .3rem;

      h6.biryani_regular {
        margin: 0;
      }

      &_dismiss-button {
        color: #292323;

        &.btn {
          width: fit-content;
          margin: 0;
          padding: 0;

          &:hover {
            color: black;
          }
        }

        &.btn-link {
          text-decoration: underline;
        }
      }
    }

    &_list {
      padding-left: 20px;

      &_item {
        a {
          text-decoration: underline;
          color: #292323;
        }
      }
    }
  }
}