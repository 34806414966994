.outside-activities-checkbox {
  @extend .biryani_semi_bold;
  font-size: 16px;
  display: flex;

  &_real-label, label {
    margin-bottom: initial !important;
    text-indent: 0 !important;
    cursor: pointer;
  }

  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.75;
  }

  &_disabled {
    cursor: default;
    opacity: 1 !important;

    .outside-activities-checkbox_real-label {
      opacity: 0.5;
      cursor: default !important;
    }
  }
}

// changes the color of the label
.state {
  color: $forest-green !important;
}
