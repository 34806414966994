$background-color: white;

.edit-company {
  margin: 0 1rem;
  padding-top: $non-authenticated-page-padding-top;

  @include media-breakpoint-up(md) {
    margin: 0 4rem;
    padding-top: $authenticated-page-padding-top;
  }

  &_form {
    border: 1px solid $silverGray;

    &_edit-name {
      display: flex;
      flex-direction: column;
      padding: .5rem .5rem .5rem 1.75rem;
      border-bottom: $border-bottom;
      background-color: $background-color;

      @include media-breakpoint-up(sm) {
        display: flex;
        flex-direction: row;
      }

      &_input {
        width: 100%;
        margin: 0 0 1rem 0;

        @include media-breakpoint-up(xs) {
          width: 75%;
        }

        @include media-breakpoint-up(sm) {
          width: 50%;
          margin-bottom: 0;
          margin-right: .5rem;
        }
      }

      &_actions {
        height: 48px;
        display: flex;
        flex-direction: row;

        &_cancel {
          padding-left: .75rem;
          text-decoration: underline;
          color: #292323;
        }
      }
    }

    &_container {
      padding: 1.75rem;
      background-color: $background-color;

      &_inner {
        @include custom-input();
        display: flex;
        flex-direction: column;
        padding-bottom: 1rem;
        border-bottom: $border-bottom;

        @include media-breakpoint-up(sm) {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-bottom: 0;
        }

        &_edit-image {
          display: flex;
          flex-direction: column;
          margin-bottom: .5rem;

          &_image {
            overflow: hidden;
            width: 128px;
            height: 128px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 1rem;
            margin-bottom: 0;
            border-radius: 50%;

            @include media-breakpoint-up(sm) {
              margin-bottom: 1rem;
            }

            img {
              width: 128px;
              height: 128px;
            }
          }

          &_actions {
            display: flex;
            margin-top: 0;

            @include media-breakpoint-up(sm) {
              margin-top: 27px;
            }

            label {
              margin-bottom: 0;
            }

            .btn {
              margin-left: .5rem;
              border-radius: 8px;
            }
          }
        }
      }

      &_bio {
        width: 100%;

        @include media-breakpoint-up(sm) {
          width: 75%;
        }

        h4 {
          margin-top: 1rem;
        }
      }

      &_form-actions {
        display: flex;
        padding-left: 2rem;
        padding-bottom: 2rem;
        background-color: $background-color;

        &_save {
          margin-right: .5rem;
        }

        .btn {
          border-radius: 8px;
        }
      }
    }
  }
}