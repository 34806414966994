.outside-text-button {
  color: #292323;
  cursor: pointer;

  &:hover {
    color: #292323;
    opacity: 0.9;
    text-decoration: underline;
  }
}
