$add-equipment-additional-images-gallery-gap: 1rem;

.add-equipment-additional-images-gallery {
  width: 100%;
  display: inline-flex;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $add-equipment-additional-images-gallery-gap;

  &_item {
    @include flex-row-item-width-helper($add-equipment-additional-images-gallery-gap, 3);
  }
}
