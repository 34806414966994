.personal-info-page {
  @extend .authenticated-user-page;
  min-height: 100%;

  &__container {
    position: relative;

    &__navbox {
      display: flex;
      flex: 1;

      &-spacer {
        display: none;

        @include media-breakpoint-up(sm) {
          display: initial;
        }
      }

      &-position {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        padding-top: 0;

        @include media-breakpoint-up(sm) {
          padding-top: 14rem;
        }
      }
    }

    &__body {
      display: flex;
      flex: 3;

      &-container {
        display: flex;
        flex-direction: column;
        flex: 1;

      }

      &-breadcrumbs {
        display: none;

        @include media-breakpoint-up(lg) {
          display: flex;
        }

        &__mobile {
          display: flex;

          @include media-breakpoint-up(lg) {
            display: none;
          }
        }
      }
    }
  }
}
