.view-inventory-item-thumbnail {
  &_positioner {
    width: 100%;
    display: flex;
    justify-content: center;

    &_sizer {
      width: 100%;
      max-width: 300px;
      aspect-ratio: 1;
      border-radius: 8px;
      border: $outside-card-border;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
      }
    }
  }
}
