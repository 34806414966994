$outside-button-height: 42px;
$outside-button-border: 1px solid;

.outside-button-2 {
  @extend .biryani;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 5px 1.5rem;
  min-height: $outside-button-height;
  box-shadow: 1px 1px 0px #00000029;

  svg {
    @include svg-sizer(18px);
    background-color: transparent;
  }

  &_left-icon {
    margin-right: 0.5em;
  }

  &_right-icon {
    margin-left: 0.5em;
  }

  &:active,
  &:focus {
    box-shadow: none;
    outline: none;
  }

  &_narrow {
    min-height: calc(#{$outside-button-height} / 1.5);
  }

  &_orange {
    border: $outside-button-border #A72A14;
  }

  &_white {
    color: $darkPurple !important;
    border: $outside-button-border #C4C3C3;

    &:hover {
      background-color: $lightGray;
      //background-color: $darkPurple;
      //color: $white !important;
      //box-shadow: none;
    }
  }

  &_outline-red {
    border: $outside-button-border #FE3328;
    color: #FE3328;
    background-color: $offWhite2;

    &:hover {
      background-color: #FE3328;
      color: $white;
    }
  }

  &_forestGreen {

  }
}
