@import "colors.module";

$container-max-widths: (
        sm: 540px,
        md: 780px,
        lg: 960px,
        xl: 1140px,
        xxl: 1400px,
);

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$custom-colors: (
        "safetyOrange": $safety-orange,
        "safetyOrange0": $safety-orange-0,
        "safetyOrange100": $safety-orange-100,
        "safetyOrange200": $safety-orange-200,
        "safetyOrange300": $safety-orange-300,
        "safetyOrange400": $safety-orange-400,
        "safetyOrange500": $safety-orange-500,
        "safetyOrange600": $safety-orange-600,
        "safetyOrange800": $safety-orange-800,
        "safetyOrange900": $safety-orange-900,

        "forestGreen": $forest-green,
        "forestGreen0": $forest-green-0,
        "forestGreen100": $forest-green-100,
        "forestGreen200": $forest-green-200,
        "forestGreen300": $forest-green-300,
        "forestGreen400": $forest-green-400,
        "forestGreen500": $forest-green-500,
        "forestGreen600": $forest-green-600,
        "forestGreen800": $forest-green-800,
        "forestGreen900": $forest-green-900,

        "grayscale0": $grayscale-0,
        "grayscale100": $grayscale-100,
        "grayscale200": $grayscale-200,
        "grayscale300": $grayscale-300,
        "grayscale400": $grayscale-400,
        "grayscale500": $grayscale-500,
        "grayscale600": $grayscale-600,
        "grayscale700": $grayscale-700,
        "grayscale800": $grayscale-800,
        "grayscale900": $grayscale-900,

        "blueLight": $blue-light,
        "blueDark": $blue-dark,

        "redLight": $red-light,
        "redDark": $red-dark,

        "offWhite": $offWhite,
        "offWhite2": $offWhite2,

        "lightGray": $lightGray,
        "mediumGray": $mediumGray,
        "darkGray": $darkGray,
        "silverGray": $silverGray,

        "border-gray": $border-gray,

        "darkPurple": $darkPurple,
        "darkBlue": $darkBlue,
);

$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

/* Buttons */
$btn-padding-y: 0.5rem;
$btn-padding-x: 2rem;
$btn-font-size: 0.85rem;

$btn-border-width: 1px;

$btn-border-radius: 15px;

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
    color: $grayscale-0 !important;
  }

  &-outline-#{$color} {
    @include button-outline-variant($value);
    //@include hover() {
    //}
  }
}

/* Inputs */
$input-padding-x: 1.1rem;
$input-font-size: 0.85em;

$input-bg: white;
$input-disabled-bg: $grayscale-300;

$input-color: $grayscale-800;
$input-plaintext-color: darkPurple;
$input-border-color: $grayscale-400;
$input-border-width: 1px;
//$input-box-shadow: 2px 2px 5px black;

$input-border-radius: 8px;

$input-focus-box-shadow: 0 0 2px $forest-green;
$input-focus-border-color: $forest-green;
$input-focus-color: $grayscale-900;
$input-btn-focus-width: rgba($forest-green, .25);

$input-placeholder-color: $grayscale-400;
$input-height: 48px;


@import "~bootstrap";

$custom-input-font-size: 16px;
$custom-input-height: 38px;
/* Inputs */
input {
  font-family: 'Biryani', sans-serif;
  font-size: $custom-input-font-size !important;
  font-weight: 400;

  &:hover {
    border-color: $forest-green;
  }
}

.admin-form-control {
  input {
    &:hover {
      border-color: $safety-orange;
    }

    &:focus {
      border-color: $safety-orange;
      outline: 0;
      box-shadow: 0 0 2px $safety-orange;
    }
  }
}

select {
  font-family: 'Biryani', sans-serif;
  font-size: 16px !important;
  font-weight: 400;
}

textarea {
  font-family: 'Biryani', sans-serif;
  font-size: 16px !important;
  min-height: 140px;
  max-height: 420px;
}

/* Custom Checkboxes */
@import '~pretty-checkbox/src/scss/variables';
$pretty--colors: $theme-colors;
@import "~pretty-checkbox/src/pretty-checkbox.scss";
