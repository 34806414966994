@mixin notification-template($name, $color, $background-color) {
  .notification-#{$name} {
    display: flex;
    flex-direction: row;
    border: 1px solid $color;
    border-radius: 15px;
    background-color: $background-color;
    margin: 1rem;
    padding: 1rem;

    &__icon {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 5px 7px 0 0;

      svg {
        @include svg-sizer(20px);
        fill: $color;
      }
    }

    &__message {
      color: $color;
    }
  }

}

@include notification-template(blueLight, $blue-dark, $blue-light);
@include notification-template(redLight, $red-dark, $red-light);
