.user-page {
  padding-top: $authenticated-page-padding-top;

  &_bread-crumbs {
    margin-bottom: 1rem;
  }

  &_event-list {
    margin: 1rem 0;
  }

  &_questionnaire-table {
    margin: 1rem 0;
  }

  &_user-image {
    border: 1px solid $silverGray;
    background-color: white;

    h2 {
      padding: 1rem;
      border-bottom: $border-bottom;
    }

    &_body {
      display: flex;
      flex-direction: row;
      padding-left: 1rem;
      padding-right: 1rem;

      &_image {
        @include flex(column, start, start);
        width: 150px;
        height: 150px;
        border-radius: 8px;
      }

      &_description {
        padding-left: .75rem;
      }
    }
  }
}