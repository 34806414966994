.switcher-button {
  @extend .biryani_extra_bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  max-width: 700px !important;

  &-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33%;
    padding: .5rem 0;
    border: 1px solid $grayscale-400;
    background-color: transparent;
    color: $grayscale-800;

    &:hover {
      color: $forest-green;
    }

    &:focus {
      outline: none;
    }

    &:first-of-type {
      border-bottom-left-radius: 15px;
      border-top-left-radius: 15px;
    }

    &:last-of-type {
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;
    }

    &-selected {
      border-color: $forest-green;
      background-color: $forest-green;
      color: white;

      &:hover {
        color: white;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .tabs-button-item {
    padding: .5rem 2rem;
  }
}