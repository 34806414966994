.homepage-search {
  &__modal {
    display: inline-flex;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 4rem;

    &-container {
      background-color: white;
      border: 1px solid $grayscale-400;
      border-radius: 0.5rem;
      color: $black;
      width: 100%;
      max-width: 345px;
      padding: 1.5rem;

      &__element {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
          flex-direction: row;
        }

        &-text {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0.5rem;
          width: 100%;
          margin-right: 0;
          margin-bottom: 1rem;

          &:hover {
            cursor: pointer;
          }

          @include media-breakpoint-up(md) {
            margin-right: 1rem;
          }

          &__left {
            display: flex;
            align-items: center;
            flex: 1;
            height: 40px;
            padding: 0 1rem;

            .outside-date-picker {
              width: 100%;
            }
          }

          &__right {
            display: flex;
            align-items: center;
            flex: 1;
            height: 40px;
            border-right: 1px solid $grayscale-400;
            border-top: 1px solid $grayscale-400;
            border-bottom: 1px solid $grayscale-400;
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
            padding: 0 1rem;
          }
        }

        &-button {
          width: 100%;

          @include media-breakpoint-up(md) {
            width: auto;
          }

          &-container {
          }
        }
      }
    }
  }
}
