.partner-upcoming-calendar-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .puci {
    &_date-and-image {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (min-width: 460px) {
        width: initial;
      }

      &_date-con {
        margin-right: 1.5rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &_month {
          margin-bottom: 0;
          text-align: center;
        }

        &_date {
          margin-top: 4px;
          margin-bottom: -4px;
          text-align: center;
        }

        &_day {
          margin-bottom: 0;
          text-align: center;
        }
      }

      &_image-container {
        width: 72px;
        height: 72px;
        background-color: #EEEDED;
        border: 1px solid $silverGray;
        border-radius: 8px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &_details {
      width: 100%;
      margin-top: 0.75rem;

      @media screen and (min-width: 460px) {
        width: initial;
        flex: 1;
        margin-top: 0;
        padding-left: 1.25rem;
      }

      @include media-breakpoint-up(md) {
        padding-right: 1.25rem;
      }
    }

    &_schedule-link-container {
      width: 100%;
      margin-top: 0.5rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;

      @include media-breakpoint-up(md) {
        width: initial;
        margin-top: 0;
      }

      &_link {
        color: $black;
        font-size: 0.9em;
        cursor: pointer;
        text-decoration: none;
        transition: all 0.2s;
        opacity: 0.8;

        &:hover {
          opacity: 1;
          text-decoration: underline;
        }
      }
    }
  }
}
