$card-page-split-layout-container-gap: 1.5rem;

@mixin card-page-col-sizer($width) {
  width: calc(#{$width} - calc(#{$card-page-split-layout-container-gap} / 2));
}

.card-page-item-base {
  display: inline-flex;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: $card-page-split-layout-container-gap;
}

.card-page-split-layout-container {
  @extend .card-page-item-base;
  align-items: flex-start;

  .card-page-col-left {
    @extend .card-page-item-base;

    @include media-breakpoint-up(md) {
      @include card-page-col-sizer(63%);
    }
  }

  .card-page-col-right {
    @extend .card-page-item-base;

    @include media-breakpoint-up(md) {
      @include card-page-col-sizer(37%);
    }
  }
}

.card-page-split-layout-container_aside {
  @extend .card-page-item-base;
  align-items: flex-start;

  .card-page-col-left {
    @extend .card-page-item-base;

    @include media-breakpoint-up(sm) {
      @include card-page-col-sizer(45%);
    }

    @include media-breakpoint-up(md) {
      @include card-page-col-sizer(40%);
    }

    @include media-breakpoint-up(lg) {
      @include card-page-col-sizer(27%);
    }
  }

  .card-page-col-right {
    @extend .card-page-item-base;

    @include media-breakpoint-up(sm) {
      @include card-page-col-sizer(55%);
    }

    @include media-breakpoint-up(md) {
      @include card-page-col-sizer(60%);
    }

    @include media-breakpoint-up(lg) {
      @include card-page-col-sizer(73%);
    }
  }
}

.card-page-header-row {
  width: 100%;
  display: inline-flex;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  flex-direction: column;
  align-items: center;
  gap: $card-page-split-layout-container-gap;
  justify-content: space-between;
  margin-bottom: calc(#{$card-page-split-layout-container-gap} / 2);

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }

  &_title {
    margin-bottom: 0;
    text-align: center;

    @include media-breakpoint-up(sm) {
      text-align: initial;
    }
  }
}

/*
Use this class for form page headers in conjunction with .card-page-mobile-button-actions for moving the location of the button for mobile
(Using this class makes the button disappear on mobile so use .card-page-header-row in places like view-pages where you want to keep
the edit button at the top always).
*/
.card-page-header-row-responsive {
  @extend .card-page-header-row;

  button {
    display: none;

    @include media-breakpoint-up(xl) {
      display: initial;
    }
  }
}

.table-page-header-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: calc(#{$card-page-split-layout-container-gap} / 2);
  margin-bottom: 1.25rem;

  @include media-breakpoint-up(sm) {
    display: inline-flex;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    flex-direction: row;
    gap: calc(#{$card-page-split-layout-container-gap} / 2);
    justify-content: space-between;
  }

  &_title {
    margin-bottom: 0;
    text-align: center;

    @include media-breakpoint-up(sm) {
      text-align: left;
    }
  }

  button {
    //margin-top: 0.5rem;

    @include media-breakpoint-up(sm) {
      margin-top: 0;
    }
  }
}

.card-page-mobile-button-actions {
  margin-top: $card-page-split-layout-container-gap;
  width: 100%;
  display: inline-flex;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  flex-direction: row;
  gap: $card-page-split-layout-container-gap;
  justify-content: flex-end;
  flex-wrap: wrap;

  @include media-breakpoint-up(xl) {
    display: none;
  }
}
