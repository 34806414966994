.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  background-color: $offWhite;

  @media screen and (max-height: 639px) {
    padding: 1rem 0 !important;
  }

  @media screen and (min-height: 640px) {
    height: calc(#{100vh} - #{$navigation-top-height}) !important;
  }

  &_images {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 5px;

    &_media-helper {
      display: none;

      @media screen and (max-height: 639px) {
        display: none !important;
      }

      @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    }


    &_top {
      aspect-ratio: 2;
      height: 100%;
      width: 100%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }

    &_bottom {
      aspect-ratio: 2;
      height: 100%;
      width: 100%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }
  }

  &-body-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_terminal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 464px;
    max-height: 628px;
    padding: 2rem;
    box-shadow: 0 3px 9px #00000033;
    border: 1px solid $silverGray;
    background-color: $grayscale-0;
    border-radius: 5px;

    &_header {
      border-bottom: 1px solid $silverGray;

      p {
        font-size: 1rem;
      }
    }

    &_form {
      display: flex;
      flex-direction: column;

      &-input {
        display: flex;
        flex-direction: column;
        margin: 1rem 0;

        input {
          border-radius: 7px;
        }
      }

      &_check-and-reset {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include media-breakpoint-up(sm) {
          flex-direction: row;
        }

        a {
          font-weight: 600;
          color: $forest-green;
          text-align: center;
          margin-top: 1.5rem;

          @include media-breakpoint-up(sm) {
            text-align: right;
            margin-top: 0;
          }
        }
      }

      .btn {
        max-width: 400px !important;
      }

      &_button {
        //border-radius: 7px;
        margin: 1.75rem 0 1.5rem 0;
      }
    }

    &_footer {
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.5rem 2.5rem;
      border: 1px solid $silverGray;
      border-radius: 7px;
      background-color: #F9F9F9;
      flex-direction: column;

      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }

      a {
        padding-left: .5rem;
        color: $forest-green;
        margin-top: 0.5rem;
        text-align: center;

        @include media-breakpoint-up(sm) {
          margin-top: 0;
          text-align: left;
        }
      }
    }
  }
}
