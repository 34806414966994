$history-view-item-info-icon-gap: 1rem;

.history-view-item-info {
  &__icon-container {
    display: inline-flex;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.5rem;
    gap: $history-view-item-info-icon-gap;

    &-empty-characteristics {
      padding-bottom: 1rem;
      text-align: center;
      color: $mediumGray;
      width: 100%;
    }

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      @include media-breakpoint-up(md) {
        @include flex-row-item-defined-width-helper($history-view-item-info-icon-gap, 2, 50%);
      }

      @include media-breakpoint-up(xl) {
        @include flex-row-item-defined-width-helper($history-view-item-info-icon-gap, 3, 33%);
      }


      & > div {
        display: flex;
        flex-direction: column;

        &:first-child {
          padding-right: 0.5rem;
        }
      }
    }
  }

  &__body-container {
    p {
      margin-bottom: 0;
    }
  }
}
