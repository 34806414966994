.user-questionnaire-question-page {
  &_question-title {
    margin-top: 0.25rem;
    margin-bottom: 2rem;
  }

  &_text-content {
    margin-bottom: 2rem;

    p {
      color: #6E6B6B;

      a {
        font-family: inherit;
      }
    }
  }

  .outside-card {
    position: relative;
  }

  &_loading-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background-color: transparent;
    transition: background-color 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;

    .local-loading {
      display: none;
    }

    &_visible {
      width: 100%;
      height: 100%;
      background-color: rgba($mediumGray, 0.25);

      .local-loading {
        display: initial;

        div {
          color: $white;
        }
      }
    }
  }
}
