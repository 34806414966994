.add-inventory-gallery {
  &_empty-message {
    text-align: center;
    margin-bottom: 0;
    font-style: italic;
  }

  &_file-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
  }
}
