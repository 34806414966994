.attributes-input {
  &_title-row {
    padding: calc(#{$outside-card-padding} / 2) $outside-card-padding;
    display: flex;
    flex-direction: row;

    &_col {
      flex: 1;

      label {
        margin-bottom: 0 !important;
      }
    }

    &_gap {
      width: $outside-card-padding;
    }
  }

  &_entry {
    padding: calc(#{$outside-card-padding} / 2) $outside-card-padding;

    &:first-of-type {
      padding-top: 0;
    }

    &:nth-child(even) {
      background-color: $offWhite2;
    }

    &_row {
      display: flex;
      flex-direction: row;

      &_col {
        flex: 1;

        label {
          margin-bottom: 0 !important;
        }
      }

      &_gap {
        width: $outside-card-padding;
      }
    }

    &_remover {
      display: flex;
      justify-content: end;

      a {
        color: $red-dark;
        font-size: 11pt;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &_empty-message-container {
    padding: $outside-card-padding;

    p {
      margin-top: 1rem;
      text-align: center;
      font-style: italic;
    }
  }

  &_button-container {
    @extend .frame-one-button-actions;
    padding: $outside-card-padding;
    padding-top: calc(#{$outside-card-padding} / 2);
  }
}
