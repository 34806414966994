.upcoming-event-card {
  display: flex;
  padding: 0.75rem 0.5rem;

  &_start-date {
    @include flex(column, center, center);
    font-size: $reg-font-size;

    strong {
      font-size: 24px;
    }
  }

  &_img {
    @include flex(column, flex-start);
    margin: 0.25rem 1rem 0 1rem;

    img {
      border-radius: 8px;
    }
  }

  &_content {
    &_item-name {
      text-decoration: underline;
      font-size: $reg-font-size;
      color: #292323;

      &:hover {
        color: black;
      }
    }

    &_item-details {
      font-size: $reg-font-size;
      margin-top: .25rem;
      margin-bottom: 0;
    }
  }
}