.preview-bundle-chars-desc {
  &_icon-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 1.5rem;

    &_item {
      //display: flex;
      //flex-direction: row;
      //align-items: center;
      width: 100%;

      @include media-breakpoint-up(md) {
        @include flex-row-item-defined-width-helper($history-view-item-info-icon-gap, 2, 50%);
      }

      @include media-breakpoint-up(xl) {
        @include flex-row-item-defined-width-helper($history-view-item-info-icon-gap, 3, 33%);
      }


      & > div {
        display: flex;
        flex-direction: column;

        &:first-child {
          padding-right: 0.5rem;
        }
      }
    }
  }

  &_body-container {
    p {
      margin-bottom: 0;
    }
  }
}
