@import url('https://fonts.googleapis.com/css2?family=Biryani:wght@400;600;700;800;900&display=swap');

@mixin biryani_base($weight) {
  font-family: "Biryani", sans-serif !important;
  font-style: normal;
  font-weight: $weight;
}

.biryani {
  @include biryani_base(400);
}

.biryani_regular {
  @include biryani_base(400);
}

.biryani_semi_bold {
  @include biryani_base(600);
}

.biryani_bold {
  @include biryani_base(700);
}

.biryani_extra_bold {
  @include biryani_base(800);
}
