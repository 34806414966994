.display-personal-info {

  input {
    max-width: 310px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 1rem 0;

    @include media-breakpoint-up(sm) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    & > :first-child {
      display: flex;
      flex: 1;
      margin: 0;
    }

    &-button {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      width: 100%;

      @include media-breakpoint-up(sm) {
        width: inherit;
      }
    }
  }

  &__image-container {
    padding: 1rem 0;
  }

  &_detail-container {
    @extend .biryani_regular;
    padding-top: 1rem;
    border-top: $border-bottom;

    &_header {
      @include flex(row, space-between, center);

      h4 {
        margin-bottom: 0;
        padding: 0.5rem 0;
      }
    }

    &_full-name {
      display: flex;
      flex-direction: row;

      & > :first-child {
        padding-right: 0.5rem;
      }

      & > :last-child {
        padding-left: 0.5rem;
      }
    }

    &_element {
      display: flex;
      flex-direction: column;
      padding-bottom: 1.5rem;
    }

    &_phone-number-container {
      display: flex;
      width: 100%;

      & > :first-child {
        display: flex;
        width: 33.3%;
        max-width: 100px;
        padding-right: 0.5rem;
      }

      & > :last-child {
        display: flex;
        width: 66.6%;
        padding-left: 0.5rem;
      }
    }

    &_button-container {
      padding: 2rem 0;
    }
  }
  .disable {
    display: none;
  }
}
