.bread-crumb-container {
  display: inline-flex;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.25rem;
  opacity: 0.75;
  margin-bottom: 1rem;

  &_item {
    @include flex(row, flex-start, center);
    font-size: 0.9em;

    &_icon {
      @include svg-sizer(1em);
      color: $darkPurple;
      margin-right: 0.2rem;
    }

    &_link {
      color: $darkPurple;

      &_disabled {
        pointer-events: none;
      }

      &:hover {
        color: $darkPurple;
        text-decoration: underline;
      }
    }
  }
}
