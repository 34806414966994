@import "colors.module";

/* Variables used for authenticated pages, authenticated pages have the full nav bar */
$authenticated-page-padding-top: 186px;
$non-authenticated-page-padding-top: 101px;

/* misc css variables that are used for the users homepage */
$border-bottom: 1px solid $silverGray;
$reg-font-size: 14px;

/* Sidebar variables; used in a couple of css files & in the tsx files */
$sidebarVisibleMinWidth: 992px;
$sidebar-section-padding: 1.75rem;
$sidebar-white-adjuster: rgba($grayscale-200, 0.85);
//$sidebar-highlight-purple-adjuster: rgba($highlightPurple, 0.5);

/* navigation variables; used in a couple of css files & in the tsx files for spacing */
$navigation-top-height: 80px;
$navigation-bottom-height: 72px;
$navigation-combined-height: calc(#{$navigation-top-height} + #{$navigation-bottom-height});

:export {
  sidebarVisibleMinWidth: $sidebarVisibleMinWidth;
}

