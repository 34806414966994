.profile-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(max(1vw, 12px));

  &_image-container {
    padding: 3px;
    border: 1px solid $grayscale-600;
    border-radius: 50%;

    &_inner {
      overflow: hidden;
      width: 111px;
      border-radius: 50%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__name {
    @extend .biryani_extra_bold;
    font-size: 22px;
    padding: 1em 0 .2em 0;
    margin: 0;
    text-align: center;
  }

  &__location {
    @extend .biryani_regular;
    font-size: 16px;
    color: $grayscale-600;
    text-align: center;
  }
}