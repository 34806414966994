.view-history-bundle-thumbnail {

  &__left-container {
    justify-content: center;
  }

  &__right-container {
    justify-content: center;

    &-link {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }

  &__image-container {
    display: flex;
    justify-content: center;
    aspect-ratio: 1;
    width: 100%;
    padding-bottom: 1rem;

    & > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__header-container {

    &__subheader {
      color: $mediumGray;
    }
  }

  &__icon {
    justify-content: flex-end;

    &-container {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      &-icon {
        aspect-ratio: 1;
        width: 20px;
        height: 30px;
        margin: 0 0.25rem;

        & > img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }

      &-value {
        color: $mediumGray;
        padding: 0 0.25rem;
      }

      &-sub-value {
        color: $mediumGray;
        padding: 0 0.25rem;
      }
    }
  }
}
