$preview-bundle-gallery-base-gap: 0.65rem;
$preview-bundle-gallery-base-height: 460px;
$preview-bundle-gallery-side-item-height: calc(calc(#{$preview-bundle-gallery-base-height} - calc(#{$preview-bundle-gallery-base-gap} * 3)) / 4);
$preview-bundle-gallery-side-item-width: calc(#{$preview-bundle-gallery-side-item-height} * 16/9);

$preview-bundle-gallery-side-item-border-radius: 10px;

.preview-bundle-gallery {
  width: 100%;
  gap: $preview-bundle-gallery-base-gap;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(xxl) {
    flex-direction: row;
  }

  &_thumbnail-container {
    flex: 1;
    aspect-ratio: 16/9;
    border-radius: $preview-bundle-gallery-side-item-border-radius;
    background-color: $lightGray;
    max-width: 100%;

    &_capped {
      @include media-breakpoint-up(xxl) {
        max-width: calc(100% - #{$preview-bundle-gallery-side-item-width} - #{$preview-bundle-gallery-base-gap});
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $preview-bundle-gallery-side-item-border-radius;
    }
  }

  &_gallery-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $preview-bundle-gallery-base-gap;

    @include media-breakpoint-up(xxl) {
      height: $preview-bundle-gallery-base-height;
      flex-direction: column;
      flex-wrap: initial;
    }

    &_item {
      @include flex-row-item-width-helper($preview-bundle-gallery-base-gap, 4);
      border-radius: $preview-bundle-gallery-side-item-border-radius;
      background-color: $lightGray;
      border: 1px solid $grayscale-100;
      cursor: pointer;
      transition: all 0.2s;

      @include media-breakpoint-up(xxl) {
        height: $preview-bundle-gallery-side-item-height;
        aspect-ratio: 16/9;
        width: initial;
      }

      &:hover {
        opacity: 0.85;
      }

      &_selected {
        border-color: $grayscale-600;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $preview-bundle-gallery-side-item-border-radius;
      }
    }
  }
}
