.icon-header-label {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;

  @include media-breakpoint-up(md) {
    padding: 0;
  }

  &__icon {
    padding-right: 0.5rem;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
