html,
body {
  height: 100%;
}

#root {
  height: 100%;
  background-color: #F9F9F9;
}

main {
  //height: 100%;
}
