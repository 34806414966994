.confirm-booking-success-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    @include svg-sizer(65px);
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  &_title {
    text-align: center;
    margin-bottom: 1.25rem;
  }
}

.confirm-booking-success-modal-dismiss-button {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}
