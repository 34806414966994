$frame-one-table-border: 2px solid $lightGray !important;

.frame-one-table-container {

  .content-table {
    margin: 0;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 10px;
  }

  thead > tr > th {
    background-color: transparent !important;
    color: $darkPurple;
  }

  tbody > tr {
    background-color: $white !important;
    border: $frame-one-table-border;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    transition: all 0.2s;
    color: $darkPurple;

    &:hover {
      background-color: rgba($lightGray, 0.25) !important;
    }

    td {
      border-top: $frame-one-table-border;

      &:first-of-type {
        border-left: $frame-one-table-border;
      }

      &:last-of-type {
        border-right: $frame-one-table-border;
      }
    }
  }

  th, td {
    padding: 1.15rem;
    font-size: 1.1em;
  }

  thead th {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }

  .frame-one-table-header-cell {
    display: flex;
    flex-direction: row;
    align-items: center;

    .frame-one-sorting-icons-container {
      margin-left: 8px;
      display: flex;
      flex-direction: column;

      &__icon {
        &__up {
          margin-bottom: -5px;
        }
      }
    }
  }

  .sortable-th {
    transition-duration: 0.2s !important;
    &:hover {
      opacity: 0.6
    }
  }

  .frame-one-table-cell {
    display: flex;
  }

  .justify-content-center {
    .frame-one-table-header-cell,
    .frame-one-table-cell {
      display: inline-flex;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      gap: 0.5rem;
    }
  }

  .justify-content-end {
    .frame-one-table-header-cell,
    .frame-one-table-cell {
      justify-content: flex-end;
    }
  }
}
