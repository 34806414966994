.bundle-page {
  padding-top: $non-authenticated-page-padding-top;
  background-color: #F9F9F9;

  @include media-breakpoint-up(md) {
    padding-top: $authenticated-page-padding-top;
  }

  h1 {
    margin: 1rem 0;
  }

  &_booking-dates-container {
    @include bg-and-border;
    margin-bottom: 1.5rem;

    h2 {
      margin-bottom: 0;
      padding: 1rem;
      border-bottom: $border-bottom;
    }

    &_inner {
      display: flex;
      align-items: center;
      padding: 1rem;

      &_date-container {
        width: 50%;
        display: flex;
        align-items: center;

        svg {
          @include svg-sizer(20px);
        }

        &_inner {
          display: flex;
          flex-direction: column;

          p {
            margin-bottom: 0;
            margin-left: .5rem;
          }
        }
      }
    }
  }

  &_items-block {
    @include bg-and-border;
    margin-top: 1.5rem;

    &_header {
      display: flex;
      align-items: center;
      padding: 1rem;
      border-bottom: $border-bottom;

      h1 {
        margin: 0 .5rem 0 0;
      }

      &_subtitle {
        margin-bottom: 0;
        color: #6E6B6B;
      }
    }

    &_body {
      display: flex;
      flex-wrap: wrap;

      &_item-container {
        width: 50%;
        height: 115px;
        display: flex;
        align-items: center;
        padding: 1rem;

        .company-photo {
          width: 84px;
          height: 84px;
        }

        &_inner {
          display: flex;
          flex-direction: column;
          margin-left: 1rem;

          &_text {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &_image-container {
    @include bg-and-border;
    padding: 1rem;

    .company-photo {
      width: 220px;
      height: 160px;
      margin-bottom: 1rem;
    }

    a {
      text-decoration: underline;
      color: black;
    }
  }
}