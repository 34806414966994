.history-grid-item {

  &__header-container {
    display: flex;
    flex-direction: row;

    &__subheader {
      color: $mediumGray;
      padding: 0 1rem;
    }
  }

  &__body-container {
    display: inline-flex;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;

    &__empty-item-list {
      padding: $outside-card-padding;

      p {
        margin-top: 1rem;
        text-align: center;
        font-style: italic;
      }
    }

    &__grid-item {
      display: flex;
      width: 100%;
      align-items: center;
      padding-bottom: 1rem;

      @include media-breakpoint-up(lg) {
        width: 50%;
      }

      &__image-container {
        width: 84px;
        height: 84px;
        border: 1px solid #E0E0E0;
        border-radius: 5px;

        & > img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }

      &__body-container {
        padding: 0 1rem;
      }
    }
  }
}
