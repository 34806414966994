.company-admin-manage-users-profile-image-cell {
  &_image-container {
    width: 44px;
    height: 44px;
    border-radius: 8px;
    border: 1px solid $mediumGray;
    background-color: $lightGray;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
}
