$outside-table-border: solid $grayscale-400;

.outside-table-container {

  .content-table {
    margin: 0;
  }

  thead > tr > th {
    padding: 5px 15px;
    background-color: #EEEDED;
    color: $black;
    border-top: 1px $outside-table-border;
  }

  tbody > tr {
    background-color: $white !important;
    transition: all 0.2s;

    &:last-of-type {
      border: none;

      td {
        border-bottom: 1px $outside-table-border;
      }
    }

    td {
      border-top: 1px $outside-table-border;

      &:first-of-type {
        border-left: 2px $outside-table-border;
      }

      &:last-of-type {
        border-right: 2px $outside-table-border;
      }
    }
  }

  .frame-one-table-cell {
    display: flex;
  }

  .justify-content-center {
    .frame-one-table-header-cell,
    .frame-one-table-cell {
      display: inline-flex;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      gap: 0.5rem;
    }
  }

  .justify-content-end {
    .frame-one-table-header-cell,
    .frame-one-table-cell {
      display: flex;
      justify-content: flex-end;
    }
  }
}
