.questions-page {
  margin: 0;
  padding-top: $non-authenticated-page-padding-top;
  padding-bottom: 2rem;

  @include media-breakpoint-up(sm) {
    margin: 0 4rem;
  }

  @include media-breakpoint-up(md) {
    padding-top: $authenticated-page-padding-top;
  }

  &_image {
    width: 190px;
    height: 300px;
    margin-bottom: 2rem;
  }

  &_buttons {
    .btn {
      width: 200px;
      margin-bottom: 1rem;
      border-radius: 8px;

      span > svg {
        vertical-align: center;
        margin: 0;
      }
    }

    a {
      text-decoration: underline;
      color: black;
    }
  }
}