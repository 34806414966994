.homepage {
  background-color: #F9F9F9;

  h1 {
    margin: 1rem 0;
  }

  &_columns {
    margin-top: .75rem;
  }

  &_company-card {
    margin: .75rem 0;
  }
}
