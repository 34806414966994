.init-page {
  margin: 0;
  padding-top: $non-authenticated-page-padding-top;
  padding-bottom: 2rem;

  @include media-breakpoint-up(sm) {
    margin: 0 4rem;
  }

  @include media-breakpoint-up(md) {
    padding-top: $authenticated-page-padding-top;
  }

  &_container {
    @include bg-and-border;

    h2 {
      padding: 1rem;
      border-bottom: $border-bottom;
    }

    &_form {
      padding: 1rem;

      &_name {
        width: 75%;
        margin-bottom: 1rem;
        display: flex;

        label {
          margin-right: .5rem;
        }
      }

      &_birth-date {
        width: 40%;
        margin-bottom: 1rem;

        &_inputs {
          display: flex;

          input {
            margin-right: .5rem;
          }
        }
      }

      &_address-title {
        padding-top: 1rem;
        padding-bottom: .5rem;
        border-bottom: $border-bottom;
      }

      &_address {
        width: 25%;
        margin-bottom: 1rem;

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  &_button {
    display: flex;
    justify-content: end;
    padding: 1rem;
    border: 1px solid $silverGray;
    border-top: none;

    .btn {
      width: 200px;
      border-radius: 8px;

      span > svg {
        vertical-align: center;
        margin: 0;
      }
    }
  }
}