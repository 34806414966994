.edit-personal-info {
  &_detail-container {
    @extend .biryani_regular;
    padding-top: 1rem;
    border-top: $border-bottom;

    &_first-name {
      margin-right: .5rem;
    }

    &_email {
      width: 75%;
    }

    &_buttons {
      width: 298px;
      display: flex;
      justify-content: space-between;
      margin: 1rem 0;
    }

    &_header {
      @include flex(row, space-between, center);

      h4 {
        margin-bottom: 0;
      }

      .btn.disabled {
        text-decoration: none;
        background-color: transparent;
        border: none;
      }
    }

    &_phone-number {
      display: flex;
      width: 75%;

      &_country-code {
        width: 25%;
        margin-right: .5rem;
      }
    }
  }
}