$calendar-component-border: 1px solid $grayscale-400;
$calendar-component-border-none: 1px solid transparent;

.calendar {
  &_section-header {
    padding: 0.5rem 1rem;
    border: $calendar-component-border;
    background-color: $grayscale-100;
    margin-top: 1.25rem;
    margin-bottom: 0.75rem;
  }

  &_section-item {
    padding: 1rem 1.25rem;
    border: $calendar-component-border;

    &_first-of-month {

    }

    &_not-first-of-month {
      border-top: none;
    }
  }
}

.calendar-single-button-controller {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;

  .outside-button-2 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
}
