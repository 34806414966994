.nav-box {
  display: flex;
  flex-direction: column;
  border: 1px solid #C4C3C3;
  background-color: $white;
  border-radius: 8px;
  width: 100%;
  max-width: 240px;

  @include media-breakpoint-up(sm) {
    max-width: 250px;
  }
  @include media-breakpoint-up(md) {
    max-width: 260px;
  }

  &_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;

    &-text {
      text-align: center;
      padding: 0.5rem 0;
      margin: 0;
    }

    &_preview-container {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 64px;
      height: 64px;
      border-radius: 50%;
      background-color: $forest-green;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &_placeholder {
        @include svg-sizer(30%);
        color: $offWhite;
      }
    }
  }

  &_page-list {
    width: 100%;
    padding-left: 0;

    &_item {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      a {
        padding: 0.5rem 0;
      }

      &-selected {
        background-color: $forest-green;

        a {
          font-weight: 700;
        }

        .nav-box_page-list_item_contents {
          background-color: $offWhite2;
        }
      }

      &_contents {
        width: 98%;
        display: flex;
        align-items: center;
        padding-top: .25rem;
        padding-bottom: .25rem;

        svg {
          margin: 0 .5rem;
        }

        a {
          color: #292323;
        }
      }
    }
  }
}
