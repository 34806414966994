@mixin page-link {
  text-decoration: underline;
  color: #292323;

  &:hover {
    color: $forest-green;
  }
}

.forgot-password-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &_container {
    width: 700px;

    &_back-button {
      margin-bottom: 2rem;

      a {
        @include page-link;
      }

    }

    p {
      margin: 0;
    }

    &_form {
      margin-bottom: 1.5rem;

      input {
        margin: 1rem 0;
        border-radius: 5px;
      }

      &_button {
        .btn {
          border-radius: 5px;
        }
      }
    }

    &_login-link {

      a {
        @include page-link;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .forgot-password-page {
    &_container {
      padding: 0 .5rem;

      &_form_button {
        width: 100%;

        .btn {
          width: 100%;
          max-width: 359px;
        }
      }
    }
  }
}