.preview-booking-page {
  &_items-count {
    color: #6E6B6B;
  }

  &_about {
    width: 100%;
  }

  &_hr {
    width: 100%;
    margin: 0;
  }

  &_items {
    width: 100%;

    &_count {
      color: #6E6B6B;
    }
  }
}
