.label-with-icon {
  display: flex;
  align-items: center;

  svg {
    @include svg-sizer(1.2em);
    margin-right: 0.5em;
    color: $mediumGray;
  }

  &_label {
    margin-bottom: 0 !important;
    font-size: 1em;
    line-height: 1em;
  }
}