@mixin shadow-low() {
  box-shadow: 0 3px 6px rgba(0, 0, 0, .08);
}

@mixin shadow-medium() {
  box-shadow: 0 3px 9px rgba(0, 0, 0, .2);
}

@mixin shadow-high() {
  box-shadow: 0 6px 12px rgba(0, 0, 0, .3);
}