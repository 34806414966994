.tabs-button {
  @extend .biryani_extra_bold;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  &-item {
    padding: .5rem 4rem;
    border: transparent;
    border-bottom: 1px solid $grayscale-400;
    background-color: transparent;
    color: $grayscale-800;

    &:hover {
      color: $forest-green;
    }

    &:focus {
      outline: none;
    }

    &-selected {
      border-bottom: 2px solid $forest-green;
      color: $forest-green;

      &:hover {
        opacity: 1;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .tabs-button-item {
    padding: .5rem 2rem;
  }
}