.reset-password-page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: $non-authenticated-page-padding-top;

  &_container {
    width: 700px;

    p {
      margin: 0;
    }

    &_form {
      margin-top: 2rem;
      margin-bottom: 1.5rem;

      &_sub-text {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        margin-bottom: 1rem;

        p {
          @extend .biryani_regular;
          font-size: 14px;
          color: #6E6B6B;
        }

        .btn-link {
          @extend .biryani_regular;
          width: fit-content;
          font-size: 14px;
          text-decoration: underline;
          padding: 0;
          color: black;
        }
      }

      label {
        @extend .biryani_regular;
        font-size: 16px;
        margin: 0;
      }

      input {
        margin: .75rem 0;
        border-radius: 5px;
      }

      &_button {
        margin-top: 2rem;
        .btn {
          border-radius: 5px;
        }
      }
    }

    &_login-link {

      a {
        text-decoration: underline;
        color: #292323;

        &:hover {
          color: $forest-green;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .reset-password-page {
    &_container {
      padding: 0 .5rem;

      &_form_button {
        width: 100%;

        .btn {
          width: 100%;
          max-width: 359px;
        }
      }
    }
  }
}