.outside-card-form-buttons {
  background-color: $offWhite2;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;

  button {
    width: 190px;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  &_single-child {
    @include media-breakpoint-up(md) {
      justify-content: flex-end;
    }
  }

  &_multi-child {
    @include media-breakpoint-up(md) {
      justify-content: space-between;
    }
  }
}
