.questionnaire-answer-selection {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &_option {
    width: 100%;
    max-width: 420px;
    border-radius: 8px;
    border: 2px solid $grayscale-300;
    transition: all 0.2s;
    background-color: $white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;

    &_selectable {
      &:hover {
        border-color: rgba($forest-green, 0.5);
      }
    }

    &_selected {
      border-color: $forest-green;
    }

    &_text {
      @extend .biryani_semi_bold;
      color: $black;
      margin-bottom: 0;
      text-align: center;
      text-transform: capitalize;
      user-select: none;

      svg {
        @include svg-sizer(1.4em);
        color: $forest-green;
        margin-left: 0.5rem;
      }

      &_hidden {
        opacity: 0;
        user-select: none;
        height: 0;
      }
    }
  }
}
