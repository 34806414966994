.preview-bundle-item-individual {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  &_thumb-container {
    width: 128px;
    height: 128px;
    background-color: $grayscale-200;
    border: 1px solid #E0E0E0;
    border-radius: 5px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  &_content {
    &_quantity {
      font-size: 0.9em;
      color: $grayscale-600;
      margin-bottom: 0.5rem;
    }
  }
}
