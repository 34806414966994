.company-card {
  border: 1px solid $silverGray;

  &_header {
    border-bottom: $border-bottom;

    a {
      text-decoration: underline;
      color: #292323;

      &:hover {
        color: black;
      }
    }
  }

  &_body {
    &_img {
      @include flex(column, center, center);
      overflow: hidden;
      width: 128px;
      height: 128px;
      border-radius: 50%;
    }

    &_description {
      text-align: center;
    }
  }
}