.outside-date-picker {
  height: $custom-input-height !important;
  width: 150px;

  .rs-picker-default {
    z-index: 0 !important;
  }

  .rs-picker-toggle {
    z-index: 0 !important;
    height: 100%;
    border-radius: $input-border-radius;
    border-color: $mediumGray !important;

    &:focus-visible {
      outline: none !important;
    }
  }

  &_user {
    .rs-picker-toggle {
      &:hover {
        //border-color: $forest-green !important;
      }
    }
  }

  &_admin {
    .rs-picker-toggle {
      &:hover {
        //border-color: $safety-orange !important;
      }
    }
  }

  .rs-picker-toggle-value {
    color: $input-color !important;
    font-size: $custom-input-font-size !important;
    box-shadow: none !important;
  }

  svg.rs-picker-toggle-caret {
    @include svg-sizer(1.2em);
  }

  .rs-picker-toggle-active {
    outline: none !important;
    box-shadow: none !important;
  }

  input {
    outline: none !important;
    box-shadow: none !important;

    &:hover {
      border-color: transparent !important;
    }
  }
}
