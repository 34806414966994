@mixin mini-button-template($name, $bg-color, $border-color, $icon-color) {
  &-#{$name}_button {
    background-color: $bg-color;
    border: 1px solid $border-color;
    border-radius: 10px;
    cursor: pointer;
    @include flex(row, center, center);
    transition: all 0.2s;

    &_icon {
      @include svg-sizer(55%);
      transition: all 0.2s;
      color: $icon-color;
    }
  }
}

.icon-button-mini-container {
  display: inline-flex;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  @include mini-button-template(redDark, $safety-orange, $safety-orange, white);
  @include mini-button-template(redLight, $safety-orange-100, $safety-orange-400, $safety-orange-600);
  @include mini-button-template(grayscale, transparent, transparent, $grayscale-800);
}
