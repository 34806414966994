$outside-activities-modal-close-icon-size: 20px;

.outside-activities-modal {
  border: 1px solid $grayscale-400;
  border-radius: 15px;
  overflow: hidden;

  &_body {
    width: 100%;
    padding: 1.2rem;

    &_close-icon-container {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0.5rem;

      &_inner {
        cursor: pointer;
        transition: all 0.2s;

        svg {
          @include svg-sizer($outside-activities-modal-close-icon-size);

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }

  &_footer {
    display: inline-flex;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
    background-color: $grayscale-100;
    padding: .8rem 1rem;
  }
}
