.view-inventory-item-schedule {
  &_tags {
    width: 100%;
    display: inline-flex;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    justify-content: center;
    gap: $outside-card-padding;
    flex-wrap: wrap;
  }

  &_empty-message-container {
    p {
      margin: 0;
      text-align: center;
      font-style: italic;
    }
  }
}
