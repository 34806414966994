.history-view-item-booking-details {

  &__body-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }
}
