.stock-summary-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
  background-color: #FFFFFF;

  &_count {
    font-size: 34px;
    color: #141010;
  }

  &_subtitle {
    font-size: $reg-font-size;
    color: #292323;
  }

  &_buttons {
    display: flex;
    justify-content: flex-start;

    .btn {
      text-decoration: underline;
      padding: .5rem .5rem .5rem 0;
      color: #292323;
    }
  }
}