$bundle-preview-gap: 1.5rem;

.bundle-preview {
  display: flex;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: $bundle-preview-gap;

  &__empty-item {
    padding: $outside-card-padding;

    p {
      margin-top: 1rem;
      @extend .empty-message;
    }
  }

  &-item {

    &:hover {
      img {
        transform: scale(1.032);
      }
    }

    @include flex-row-item-width-helper(#{$bundle-preview-gap}, 1);
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      @include flex-row-item-width-helper(#{$bundle-preview-gap}, 2);
    }

    @include media-breakpoint-up(lg) {
      @include flex-row-item-width-helper(#{$bundle-preview-gap}, 3);
    }

    @include media-breakpoint-up(xxl) {
      @include flex-row-item-width-helper(#{$bundle-preview-gap}, 4);
    }
    &__image-container {
      height: 250px;
      border: 1px solid $grayscale-300;
      border-radius: 5px;
      overflow: hidden;

      & > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition-duration: 0.25s;
      }
    }

    &__body-container {
      padding-top: 0.5rem;

      &:hover {
        h3 {
          color: $forest-green;
        }
      }

      h3 {
        padding-top: 0.5rem;
        color: $forest-green-800;
        transition-duration: 0.25s;
        margin-bottom: 0.25rem;
      }

      &__subtext {
        color: $mediumGray;
      }

      &__body {
        margin-bottom: 0.5rem;

        p {
          font-size: 0.9em;
          line-height: 1.5em;
          color: $black;
          margin-bottom: 0;
          display: block;
          width: 100%;
        }

        &-link {

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
