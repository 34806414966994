.upcoming-event-list {
  border: 1px solid $silverGray;

  h2 {
    font-size: 22px;
    border-bottom: $border-bottom;
    padding: 1rem 1.25rem;
  }

  &_list {
    list-style-type: none;
    padding: 0 1rem;

    &_item {
      border-bottom: $border-bottom;
    }
  }

  &_calendar-link {
    @include flex(row, center, center);
    text-decoration: underline;
    font-size: 14$reg-font-size;
    padding-bottom: 1rem;
    color: #292323;

    &:hover {
      color: black;
    }
  }
}