.user-questionnaire-start {
  &_image-container {
    margin-top: 5rem;
    width: 100%;
    max-width: 250px;

    img {
      width: 100%;
      transform: rotateZ(180deg);
    }
  }

  &_text-content {
    margin-top: 3rem;

    h1 {
      margin-bottom: 1rem;
    }

    p {
      color: #6E6B6B;
    }

    &_start-link-container {
      display: flex;
    }

    &_start-link {
      display: flex;
      margin-top: 2rem;
      margin-bottom: 1.5rem;
      width: 100%;
      max-width: 200px;

      button {
        width: 100%;
      }
    }


    &_skip-link {
      color: $black;

      &:hover {
        color: $black;
        text-decoration: underline;
      }
    }
  }
}
