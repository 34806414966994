.loading-manager {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  background-color: rgba(black, 0.4);
}

.spinner-border {
  span {
    display: none;
  }
}
