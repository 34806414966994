.stepper {
  @extend .biryani_extra_bold;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__spacer {
    background-color: $grayscale-400;
    height: 5px;
    width: 60px;

    &:last-of-type {
      display: none;
    }
  }

  &__circle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid $grayscale-600;

    &-selected {
      border-color: $forest-green;
    }

    &__inner {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      color: $grayscale-600;

      svg {
        fill: $grayscale-600;
      }

      &-selected {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 90%;
        border-radius: 50%;
        background-color: $forest-green;
        color: white;
      }
    }
  }
}
