.navigation-master {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  z-index: 10;
}

.navigation-top-spacer {
  height: $navigation-top-height;

  //@include media-breakpoint-up(md) {
  //  height: $navigation-combined-height;
  //}
}

.navigation-bottom-spacer {
  height: $navigation-bottom-height;
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.navigation-manager {
  width: 100%;
  height: $navigation-top-height;
  padding-top: 20px;
  padding-bottom: 20px;

  &_user {
    background-color: $forest-green;
  }

  &_admin {
    background-color: $safety-orange;
  }

  &_container {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &_branding {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    &_logo-container {
      height: 100%;

      img {
        height: 100%;
      }
    }

    &_title {
      color: $white;
      font-size: 1.4em;
      margin-bottom: -7px;
      display: none;
      @extend .biryani_extra_bold;

      @media screen and (min-width: 460px) {
        display: initial;
      }
    }
  }

  &_user-controls {
    height: 100%;
    display: flex;
    align-items: center;

    &_hamburger-container {
      padding: 0.2rem;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        opacity: 0.8;
      }

      svg {
        color: $white;
        @include svg-sizer(1.6rem);
      }

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &_desktop-view {
      display: none;

      @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }

      a {
        color: $white;
        transition: all 0.1s;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.navigation-bottom {
  background-color: $white;
  width: 100%;
  height: $navigation-bottom-height;
  display: none;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: row;
  }

  &_container {
    display: flex;
    flex-direction: row;
    gap: 1.75rem;
  }
}

.navigation-bottom-button {
  height: 100%;
  display: inline-flex;
  align-items: center;
  border-top: solid 5px transparent;
  border-bottom: solid 5px transparent;
  transition: all 0.2s;
  padding-left: 12px;
  padding-right: 12px;
  user-select: none;
  cursor: pointer;
  color: $black;

  &_disabled {
    pointer-events: none;
  }

  &_selectable {
    &:hover {
      border-bottom-color: $grayscale-400;
    }
  }

  &_user-selected {
    border-bottom-color: $forest-green;
  }

  &_admin-selected {
    border-bottom-color: $safety-orange;
  }

  &:hover,
  &:focus, {
    color: $black;
  }
}
